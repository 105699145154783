import { Col, Form, Input, Modal, Row } from "antd";
import React, { useContext, useState } from "react";
import API from "../../../api";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../../store/global-context";

const TransactionForm = ({
  open,
  handleModalClose,
  uuid = null,
  selectedProduct = null,
}) => {
  const globalCtx = useContext(GlobalContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [autoGenerateLoanNumber, setAutoGenerateLoanNumber] = useState(true);

  const handleSubmit = (data) => {
    setIsLoading(true);
    let payload = {
      loan_number: !autoGenerateLoanNumber ? data?.loan_number : undefined,
      loan_number_available: !autoGenerateLoanNumber,
    };
    if (uuid) {
      payload.ttc_uuid = uuid;
      payload.quick_order = true;
    }
    if (selectedProduct) payload.product = selectedProduct;
    API.post("/transaction", payload)
      .then((res) => {
        if (res.status === 200) {
          const authToken = res?.data?.response?.auth_token?.substring(7);
          navigate(
            `/standalone/transaction/${res?.data?.response?.uuid}?token=${authToken}&theme=${res?.data?.response?.theme}`
          );
          if (globalCtx?.hasZeroTransactions) {
            globalCtx.setHasZeroTransactions(false);
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    if (!isLoading) {
      setAutoGenerateLoanNumber(true);
      setStep(1);
      handleModalClose();
      form.resetFields();
    }
  };

  return (
    <Modal
      open={open}
      title="Create Transaction"
      onCancel={handleClose}
      okText={step === 1 ? "Yes" : "Submit"}
      cancelText={step === 1 ? "No" : "Back"}
      confirmLoading={step === 2 && isLoading}
      cancelButtonProps={{
        loading: step === 1 && isLoading,
        disabled: step === 2 && isLoading,
        onClick: () => {
          if (step === 1) {
            form.submit();
          }
          if (step === 2) {
            setAutoGenerateLoanNumber(true);
            setStep(1);
          }
        },
      }}
      okButtonProps={{
        disabled: step === 1 && isLoading,
        onClick: () => {
          if (step === 1) {
            setAutoGenerateLoanNumber(false);
            setStep(2);
          }
          if (step === 2) {
            form.submit();
          }
        },
      }}
      onOk={() => {
        form.submit();
      }}
      destroyOnClose
      className="create-transaction-modal"
    >
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        form={form}
        layout={"vertical"}
        name="partnerForm"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={30}>
          {step === 1 && (
            <Col sm={24} md={24} lg={24}>
              <p className="loan-number-text">Do you have a loan number?</p>
            </Col>
          )}
          {step === 2 && (
            <Col sm={24} md={24} lg={24}>
              <Form.Item
                name="loan_number"
                label="Loan Number"
                rules={[
                  { required: true, message: "Please enter loan number" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </Modal>
  );
};

export default TransactionForm;
