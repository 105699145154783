import TitleCalculatorLayout from "./TitleCalculatorLayout";
import SignUp from "./components/Auth/SignUp";
import EmailVerification from "./components/User/EmailVerification";

import "./App.css";
import MainLayout from "./MainLayout";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./components/Auth/Login";
import AppRoutes from "./routes";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ProtectedRoute from "./components/shared/ProtectedRoute";
import ResetPassword from "./components/Auth/ResetPassword";
import "antd/dist/antd.min.css";
import { useContext } from "react";
import GlobalContext from "./store/global-context";
import AddressList from "./components/AddressVerification/AddressList";
import ImportList from "./components/AddressVerification/ImportList";
import ImportAddress from "./components/AddressVerification/ImportAddress";
import PublicRouteLayout from "./PublicRouteLayout";
import ImportDetails from "./components/AddressVerification/ImportDetails";
import TestcaseList from "./components/BestxTestCases/TestcaseList";
import ImportedTestcaseList from "./components/BestxTestCases/ImportedTestcaseList";
import ImportTestcase from "./components/BestxTestCases/ImportTestcase";
import TestcaseDetails from "./components/BestxTestCases/TestcaseDetails";
import ReverificationHistory from "./components/BestxTestCases/ReverificationHistory";
import ReverificationDetails from "./components/BestxTestCases/ReverificationDetails";
import TitleCalculator from "./components/Standalone/TitleCalculator/TitleCalculator";

function App() {
  const globalCtx = useContext(GlobalContext);

  useEffect(() => {
    const suffix = window.location.hostname.startsWith("dev")
      ? "Dev"
      : window.location.hostname.startsWith("stage")
      ? "Stage"
      : "";
    if (window.location.pathname.startsWith("/standalone")) {
      document.title = `Switchboard ${suffix}`;
    } else {
      document.title = `Switchboard Admin ${suffix}`;
    }
  }, []);

  return (
    <Routes>
      {/* {!globalCtx.token && (
        <>
          <Route
            path={
              window.location.pathname.startsWith("/standalone")
                ? "/standalone"
                : "/admin"
            }
            element={<Login />}
          />
          <Route
            path={`/${
              window.location.pathname.startsWith("/standalone")
                ? "/standalone"
                : "/admin"
            }/forgot-password`}
            element={<ForgotPassword />}
          />
          <Route
            path={`/${
              window.location.pathname.startsWith("/standalone")
                ? "/standalone"
                : "/admin"
            }/reset-password`}
            element={<ResetPassword />}
          />
        </>
      )}

     
      )} */}
      <Route path={`/`} element={<Login />} />
      <Route element={<TitleCalculatorLayout />}>
        <Route path="/title-calculator" element={<TitleCalculator />} />
      </Route>
      <Route path={`/login`} element={<Login />} />
      <Route path={`/sign-up`} element={<SignUp />} />
      <Route path={`/verify-email`} element={<EmailVerification />} />
      <Route path={`/forgot-password`} element={<ForgotPassword />} />
      <Route path={`/reset-password`} element={<ResetPassword />} />
      {/* {!globalCtx.token && (
        <>
          <Route path={`/login`} element={<Login />} />
          <Route path={`/forgot-password`} element={<ForgotPassword />} />
          <Route path={`/reset-password`} element={<ResetPassword />} />
        </>
      )} */}
      <Route element={<PublicRouteLayout />}>
        <Route path="/admin/addresses" element={<AddressList />} />
        <Route path="/admin/addresses/import" element={<ImportList />} />
        <Route path="/admin/addresses/import/add" element={<ImportAddress />} />
        <Route path="/admin/addresses/import/:id" element={<ImportDetails />} />
      </Route>
      <Route element={<PublicRouteLayout />}>
        <Route path="/admin/best-x" element={<TestcaseList />} />
        <Route
          path="/admin/best-x/reverification"
          element={<ReverificationHistory />}
        />
        <Route
          path="/admin/best-x/reverification/:id"
          element={<ReverificationDetails />}
        />
        <Route path="/admin/best-x/import" element={<ImportedTestcaseList />} />
        <Route path="/admin/best-x/import/add" element={<ImportTestcase />} />
        <Route path="/admin/best-x/import/:id" element={<TestcaseDetails />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route element={<MainLayout />}>
          <Route path="/*" element={<AppRoutes />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
