import { Col, Form, Input, Modal, Row, Select } from "antd";
import React, { useContext, useState } from "react";
import API from "../../../api";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../../store/global-context";
const { Option } = Select;

const TransactionForm = ({
  open,
  handleModalClose,
  uuid = null,
  selectedProduct = null,
}) => {
  const globalCtx = useContext(GlobalContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (data) => {
    setIsLoading(true);
    let payload = {
      loan_number: undefined,
      loan_number_available: false,
      representative: data.represent,
    };
    if (uuid) {
      payload.ttc_uuid = uuid;
      payload.quick_order = true;
    }
    if (selectedProduct) payload.product = selectedProduct;
    API.post("/transaction", payload)
      .then((res) => {
        if (res.status === 200) {
          const authToken = res?.data?.response?.auth_token?.substring(7);
          navigate(
            `/standalone/transaction/${res?.data?.response?.uuid}?token=${authToken}&theme=${res?.data?.response?.theme}`
          );
          if (globalCtx?.hasZeroTransactions) {
            globalCtx.setHasZeroTransactions(false);
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    if (!isLoading) {
      handleModalClose();
      form.resetFields();
    }
  };

  return (
    <Modal
      open={open}
      title="Create Transaction"
      onCancel={handleClose}
      okText={"Submit"}
      cancelText={"Cancel"}
      confirmLoading={isLoading}
      cancelButtonProps={{
        disabled: isLoading,
        onClick: () => {
          handleClose();
        },
      }}
      okButtonProps={{
        disabled: isLoading,
        onClick: () => {
          form.submit();
        },
      }}
      onOk={() => {
        form.submit();
      }}
      destroyOnClose
      className="create-transaction-modal"
    >
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        form={form}
        layout={"vertical"}
        name="partnerForm"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={30}>
          <Col sm={24} md={24} lg={24}>
            <Form.Item
              name="represent"
              label="Whom are you representing?"
              rules={[
                {
                  required: true,
                  message: "Please select representative role",
                },
              ]}
            >
              <Select>
                <Option key={1} value="Seller">
                  Seller
                </Option>
                <Option key={2} value="Buyer">
                  Buyer
                </Option>
                <Option key={3} value="buyer_seller">
                  Both
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default TransactionForm;
